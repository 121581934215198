import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [2,3];

export const dictionary = {
		"/(landing)": [4],
		"/(webapp)/admin": [~7,[2]],
		"/(webapp)/admin/menu/[menuFileId]": [~8,[2]],
		"/(webapp)/admin/restaurants": [9,[2]],
		"/(webapp)/dashboard": [10,[2,3]],
		"/login": [~15],
		"/logout": [~16],
		"/(misc)/privacy-policy": [5],
		"/(webapp)/profile": [11,[2]],
		"/(webapp)/profile/[slug]": [12,[2]],
		"/(webapp)/settings": [13,[2]],
		"/(webapp)/settings/billing": [~14,[2]],
		"/(misc)/terms": [6]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';